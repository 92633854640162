<!--
 * @Author: lbh
 * @Date: 2023-08-03 16:13:49
 * @LastEditors: lbh
 * @LastEditTime: 2024-04-16 09:37:24
 * @Description: file content
-->
<template>
  <div
    class="pay-module-box px-pay-module-box"
    ref="pay-module-box"
    :style="`background-image:url('${configs.bg}')`"
    @click="clickItem('pay-module-box')"
  >
    <div
      class="title"
      v-html="$util.rex.getHtml(configs[`${nowL}title`] || configs.title)"
      @click="clickItem('title')"
    ></div>
    <div
      class="desc"
      v-html="$util.rex.getHtml(configs[`${nowL}desc`] || configs.desc)"
      @click="clickItem('title')"
    ></div>

    <div
      class="items"
      ref="container"
    >
      <div
        v-for="(item, index) in allData"
        :key="index"
        class="item"
        :style="{left:`${item.x}`,top:`${item.y}`}"
      >
        <div class="icon_b">
          <img
            :src="$util.image.setOss(item.logo)"
            class="bg"
          >
          <progressiveImage
            class="logo"
            :src="item.logo"
            :h="30"
          />
          <!-- <el-image
            class="logo"
            :src="item.logo"
            lazy
          >
            <img
              slot="placeholder"
              style="height:100px;"
              :src="$util.image.setOss(item.logo,30)"
            />
          </el-image> -->
        </div>

        <div
          class="name"
          v-text="item[`${nowL}name`] || item.name"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import langMixin from '../../mixins/lang';
import ScrollTrigger from '@terwanerik/scrolltrigger';
const trigger = new ScrollTrigger();
import { gsap, TweenMax, TweenLite } from "gsap";
export default {
  name: "pay-module",
  mixins: [langMixin],
  props: {
    configs: {
      default () {
        return {}
      }
    },
    Xindex: {
      default () {
        return '';
      },
    },
  },
  watch: {
    "configs.items": {
      deep: true,
      handler () {
        this.initItem();
      }
    }
  },
  data () {
    return {
      allData: []
    }
  },
  computed: {
    isEdit () {
      return this.$store.getters.getIsEdit;
    },
    isPc () {
      return window.innerWidth > 768;
    }
  },
  mounted () {
    this.initItem();
    trigger.add(this.$refs['pay-module-box'], {
      once: true,
      offset: {
        element: {
          x: 0,
          y: (trigger, rect, direction) => {
            return 0.2
          }
        },
        viewport: {
          y: (trigger, frame, direction) => {
            return 0;// trigger.visible ? 0 : 0.2
          }
        },
      },
      toggle: {
        callback: {
          in: () => {
            // 設置標題動畫
            TweenMax.from(this.$refs['pay-module-box'].querySelector('.title'), {
              opacity: 0,
              y: 120,
              duration: 1,
              immediateRender: true
            })
            // 設置 描述動畫
            TweenMax.from(this.$refs['pay-module-box'].querySelector('.desc'), {
              opacity: 0,
              delay: 0.3,
              y: 120,
              duration: 1,
              immediateRender: true
            })
            TweenMax.from(this.$refs['pay-module-box'].querySelector('.items'), {
              opacity: 0,
              delay: 0.3,
              y: 120,
              duration: 1,
              immediateRender: true
            })
          },
        }
      }
    })
  },
  methods: {

    initItem () {
      let w_ = window.innerWidth;
      let items = this.configs.items;
      let len = items.length;
      let nowC = 1;
      let nowI = 1;
      let w = 386;
      let h = 160;
      let ah = 212;
      let l = 48;
      let t = 80;
      let $r = 'rem'
      let pxB = 192
      if (w_ < 768) {
        w = (w_ - 54) / 4;
        h = w / 1.6;
        ah = w / 1.1;
        l = 10;
        t = 20;
        $r = 'px'
        pxB = 1;
      }
      let bigH = 0;
      let allData = [];
      for (let i = 0; i > -1; i += 1) {
        let json = {};
        if (i == len) {
          let len_b = len % 7;
          if (len_b != 0 && len_b != 4) {
            nowC += 1;
          }

          bigH = (((nowC - 1) * ah) + ((nowC - 1) * t));
          break;
        }
        // * 第二行
        if (nowC % 2 == 0) {
          json = {
            x: `${((l * (nowI)) + (w / 2) + (w * (nowI - 1))) / pxB}${$r}`,
            y: `${(((nowC - 1) * ah) + ((nowC - 1) * t)) / pxB}${$r}`
          }
          if (nowI == 3) {
            nowI = 0;
            nowC += 1;
          }
        } else {
          json = {
            x: `${((l * (nowI)) + (w * (nowI - 1))) / pxB}${$r}`,
            y: `${(((nowC - 1) * ah) + ((nowC - 1) * t)) / pxB}${$r}`
          }
          if (nowI == 4) {
            nowI = 0;
            nowC += 1;
          }
        }
        nowI += 1;
        json.logo = items[i].logo;
        json.name = items[i].name

        allData.push({ ...json, ...items[i] })
      }
      this.allData = allData;
      TweenLite.set(this.$refs.container, { height: (bigH) / pxB + `${$r}`, width: ((l + w) * 4 + l) / pxB + `${$r}` });
    },

    clickItem (e, x) {
      if (this.isEdit) {
        this.$emit('clickItem', e, x);
      }
    },
  }
}
</script>

<style lang="less" scoped>
@media screen and(min-width:768px) {
  .pay-module-box {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 70px;
    .title {
      font-size: 44px;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      text-align: center;
    }
    .desc {
      font-size: 26px;
      font-family: "Microsoft Yahei";
      font-weight: 200;
      line-height: 40px;
      color: #666666;
      // padding: 0 414px;
      text-align: center;
      margin-bottom: 37px;
    }

    .items {
      position: relative;
      .item {
        width: 386px;
        position: absolute;
        height: 212px;
        display: flex;
        flex-direction: column;
        transition: 0.3s;
        &:hover {
          transform: scale(1.1);
          .icon_b {
            box-shadow: 10px -10px 17px rgba(181, 210, 252, 0.3),
              -10px 10px 17px rgba(181, 210, 252, 0.3);
          }
        }
        .icon_b {
          height: 160px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          overflow: hidden;
          background: rgba(255, 255, 255, 0.5);
          border-radius: 14px;
          box-shadow: 0px 2px 14px 0px rgba(174, 202, 242, 0.2);
          transition: 0.3s;
          .bg {
            position: absolute;
            width: 200%;
            left: 0;
            top: 0;
            z-index: 1;
            filter: blur(15px);
            opacity: 0.2;
          }
          .logo {
            max-width: 220px;
            max-height: 97px;
            width: 220px;
            height: 97px;
            position: relative;
            z-index: 2;
          }
        }
        .name {
          font-size: 26px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          margin-top: auto;
          text-align: center;
        }
      }
    }
  }
}

@media screen and(max-width:768px) {
  .px-pay-module-box {
    padding: 34px 0 22px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    .title {
      font-size: 22px;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      text-align: center;
    }
    .desc {
      font-size: 16px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      text-align: center;
      padding: 0 50px;
      margin-top: 4px;
      margin-bottom: 20px;
    }
    .items {
      position: relative;
      .item {
        position: absolute;
        --iw: calc((100vw - 54px) / 4);
        width: var(--iw);
        height: calc(var(--iw) / 1.1);
        display: flex;
        flex-direction: column;
        .icon_b {
          position: relative;
          overflow: hidden;
          height: calc(var(--iw) / 1.6);
          min-height: calc(var(--iw) / 1.6);
          display: flex;
          align-items: center;
          justify-content: center;
          background: rgba(255, 255, 255, 0.5);
          border-radius: 6px;
          box-shadow: 0 2px 8px rgba(102, 152, 224, 0.1);
          .bg {
            display: none;
          }
          .logo {
            position: relative;
            z-index: 2;
            max-height: 60%;
            max-width: 80%;
          }
        }
        .name {
          font-size: 14px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          text-align: center;
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}

@media screen and(max-width:600px) {
  .px-pay-module-box {
    .title {
      font-size: 20px;
    }
    .desc {
      font-size: 14px;
      margin-top: 4px;
      margin-bottom: 20px;
    }
    .items {
      .item {
        .name {
          font-size: 12px;
          margin-top: auto;
          display: block;
          flex: 0;
        }
      }
    }
  }
}
</style>